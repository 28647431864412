//socket key for shake-hand with server-end application
export const REACT_APP_PUSHER_APP_KEY = "pusher_id";
export const REACT_APP_PUSHER_CLUSTER = "mt1";

export const NAME_OF_SEARCH_SLICE = "module/search";

export const PERSONAL_INFORMATION_API_URL = "personal-information";
export const GET_ALL_PERSONAL_INFORMATION_API_URL =
  "get-all-personal-information";
export const PERSONAL_INFORMATION = "managePersonalInformationSlice";

export const LEAVE_APPLICATION_API_URL = "leave-application";
export const GET_ALL_LEAVE_APPLICATION_API_URL = "get-all-leave-application";
export const LEAVE_APPLICATION = "manageLeaveApplicationSlice";

export const STUDENT_COURSES_API_URL = "student-courses";
export const GET_STUDENT_COURSES_API_URL = "get-student-courses";
export const GET_STUDENT_COURSE_PLAN_API_URL = "get-student-course-plan";
export const GET_ALL_STUDENT_COURSES_API_URL = "get-all-student-courses";
export const STUDENT_COURSES = "manageStudentCoursesSlice";

export const STUDENT_ATTENDANCE_API_URL = "student-attendance";
export const GET_ALL_STUDENT_ATTENDANCE_API_URL = "get-all-student-attendance";
export const STUDENT_ATTENDANCE = "manageStudentAttendanceSlice";

export const STUDENT_CONVERSATION_API_URL = "student-conversation";
export const GET_ALL_STUDENT_CONVERSATION_API_URL =
  "get-all-student-conversation";
export const STUDENT_CONVERSATION = "manageStudentConversationSlice";

export const ABSENT_REASON_API_URL = "absent-reason";
export const GET_ALL_ABSENT_REASON_API_URL = "get-all-absent-reason";
export const ABSENT_REASON = "manageAbsentReasonSlice";

export const GET_STUDENT_CLASS = "get-student-class";

export const PROVINCE_API_URL = "province";
export const GET_ALL_PROVINCE_API_URL = "get-all-province";
export const PROVINCE = "manageProvinceSlice";

export const MANAGE_CLASS_API_URL = "manage-class";
export const ADD_CLASS_COURSE_API_URL = "add-class-course";
export const ADD_CLASS_STUDENT_API_URL = "add-class-student";
export const GET_OTHER_CLASS_API_URL = "get-other-classes";
export const GET_GRADE_STUDENT_API_URL = "get-grade-students";
export const GET_OTHER_CLASS_STUDENT_API_URL = "get-other-class-students";
export const DELETE_CLASS_STUDENT_API_URL = "delete-class-student";
export const DELETE_CLASS_COURSE_API_URL = "delete-class-course";
export const CLASS_COURSE_FILTER_API_URL = "class-course-filter";
export const CLASS_STUDENT_FILTER_API_URL = "class-student-filter";
export const GET_ALL_MANAGE_CLASS_API_URL = "get-all-manage-class";
export const GET_TAGGED_CLASS_COURSE_TEACHER_API_URL =
  "get-tagged-class-course-teachers";
export const MANAGE_CLASS = "manageManageClassSlice";
export const GET_CLASS_COURSE_SCHEDULES = "get-class-course-schedules";
export const TEACHER_MESSAGE = "teacherMsgSlice";

export const INVOICES_API_URL = "invoices";
export const GET_ALL_INVOICES_API_URL = "get-all-invoices";
export const GET_CHARGE_TYPE_INVOICE_URL = "charge-type-invoices";
export const GET_DETAIL_CHARGE_TYPE_INVOICE_URL = "detail-charge-type-invoices";
export const GET_MONTHLY_WISE_INVOICE_URL = "monthly-wise-invoices";
export const GET_DETAIL_MONTHLY_WISE_INVOICE_URL =
  "detail-monthly-wise-invoices";
export const GET_STUDENT_WISE_INVOICE_URL = "student-wise-invoices";
export const GET_DETAIL_STUDENT_WISE_INVOICE_URL =
  "detail-student-wise-invoices";
export const GET_ANNUAL_INVOICE_URL = "annual-invoices";
export const GET_DETAIL_ANNUAL_INVOICE_URL = "detail-annual-invoices";
export const GET_INVOICE_REVENUE_SUMMARY_URL = "invoices-revenue-summary";
export const DOWNLOAD_INVOICE_PDF = "download-invoice";
export const INVOICES = "manageInvoicesSlice";
export const STUDENT_INVOICES_API_URL = "student-invoices";
export const GET_ALL_STUDENT_INVOICES_API_URL = "get-all-invoices";
export const STUDENT_INVOICES = "manageStudentInvoicesSlice";
export const GET_GUARDIAN_STUDENT_INVOICES = "get-guardian-student-invoices";

export const GRADE_BOOK_CATEGORY_API_URL = "grade-book-category";
export const GET_ALL_GRADE_BOOK_CATEGORY_API_URL =
  "get-all-grade-book-category";
export const GRADE_BOOK_CATEGORY = "manageGradeBookCategorySlice";

export const GRADE_API_URL = "grade";
export const GET_ALL_GRADE_API_URL = "get-all-grade";
export const GRADE = "manageGradeSlice";
export const STUDENT_GRADE_BOOK_SLICE = "studentGradeBookSlice";
export const THEME_SETTING_API_URL = "theme-setting";
export const GET_ALL_THEME_SETTING_API_URL = "get-all-theme-setting";
export const THEME_SETTING = "manageThemeSettingSlice";

export const UPDATE_USER_PROFILE_PICTURE = "update-profile-picture";
export const REMOVE_USER_PROFILE_PICTURE = "remove-profile-picture";
export const UPDATE_CURRENT_USER_PASSWORD = "update-current-user-password";

export const CALENDAR_CATEGORY_API_URL = "calendar-category";
export const GET_ALL_CALENDAR_CATEGORY_API_URL = "get-all-calendar-category";
export const CALENDAR_CATEGORY = "manageCalendarCategorySlice";

export const CALENDAR_EVENT_API_URL = "calendar-event";
export const GET_ALL_CALENDAR_EVENT_API_URL = "get-all-calendar-event";
export const CALENDAR_EVENT = "manageCalendarEventSlice";

export const SCHOOL_YEAR = "manageSchoolYearSlice";
export const GET_ALL_SCHOOL_YEAR_API_URL = "get-all-school-year";
export const GET_SET_DEFAULT_YEAR_API_URL = "set-default-year";