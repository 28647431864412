import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
//_import_slice_here
import manageStudentInvoicesSlice from "./slices/StudentInvoices/manageStudentInvoicesSlice";
import manageStudentConversationSlice from "./slices/StudentConversation/manageStudentConversationSlice";
import manageStudentAssignmentSlice from "./slices/StudentAssignment/manageStudentAssignmentSlice";
import manageStudentAttendanceSlice from "./slices/StudentAttendance/manageStudentAttendanceSlice";
import manageStudentCoursesSlice from "./slices/StudentCourses/manageStudentCoursesSlice";
import manageLeaveApplicationSlice from "./slices/LeaveApplication/manageLeaveApplicationSlice";
import manageAbsentReasonSlice from "./slices/AbsentReason/manageAbsentReasonSlice";
import managePersonalInformationSlice from "./slices/PersonalInformation/managePersonalInformationSlice";
import manageStudentDashboardSlice from "./slices/StudentDashboard/manageStudentDashboardSlice";
import manageFluidSearchSlice from "./slices/FluidSearchSlice/manageFluidSearchSlice";
import manageProvinceSlice from "./slices/Province/manageProvinceSlice";
import manageManageClassSlice from "./slices/ManageClass/manageManageClassSlice";
import teacherMsgSlice from "./slices/teacherMsgManagement/teacherMsgSlice";
import studentGradeBookSlice from "./slices/Grade/studentGradeBookSlice";
import manageGradeBookCategorySlice from "./slices/GradeBookCategory/manageGradeBookCategorySlice";
import assignmentSlice from "./slices/Assignments/assignmentSlice";
import manageCalendarEventSlice from "./slices/CalendarEvent/manageCalendarEventSlice";
import manageSchoolYearSlice from "./slices/SchoolYear/manageSchoolYearSlice";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    //_Register_slice_after
    manageSchoolYearSlice,
    manageStudentInvoicesSlice,
    manageStudentConversationSlice,
    manageStudentAssignmentSlice,
    manageStudentAttendanceSlice,
    manageStudentCoursesSlice,
    manageLeaveApplicationSlice,
    manageAbsentReasonSlice,
    managePersonalInformationSlice,
    manageStudentDashboardSlice,
    manageFluidSearchSlice,
    manageProvinceSlice,
    manageManageClassSlice,
    manageCalendarEventSlice,
    teacherMsgSlice,
    studentGradeBookSlice,
    manageGradeBookCategorySlice,
    assignmentSlice,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
